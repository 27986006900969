/* eslint-disable max-len */

export interface LegacyPMSEvent {
	eventName: string;
	argument: any;
}

export interface ModuleActionEvent {
	argument: {
		module: string;
		action: string;
		payload: any;
		route: string;
		routePastPersistedRoute: boolean;
	};
}

export const FEATURE_NAVIGATION_EVENT = 'FEATURE_NAVIGATION';
export const LOAD_MODULE_WITH_ACTION = 'LOAD_MODULE_WITH_ACTION';
export const LOAD_ROUTE = 'LOAD_ROUTE';

export const HIT_PMS_HTML_EVENTS = {
	ADMIN: {
		ADMINISTRATION_MODULE_BARCODE_LABEL_PRINTER: 'ADMINISTRATION_MODULE_BARCODE_LABEL_PRINTER',
		AUTO_DIAGNOSIS_OPEN: 'ADMINISTRATION_MODULE_AUTO_DIAGNOSIS_OPEN',
		IMPORT_QRDA: 'ADMINISTRATION_MODULE_IMPORT_QRDA',
		CCDA_DISPLAY: 'ADMINISTRATION_MODULE_CCDA_DISPLAY',
		UPDATE_EMPLOYEE_CCDA_DISPLAY: 'ADMINISTRATION_MODULE_UPDATE_EMPLOYEE_CCDA_DISPLAY_PREFERENCES',
		CCDA_SCHEDULE_MODAL_OPEN: 'ADMINISTRATION_MODULE_LIST_CCDA',
	},
	ACCOUNTING: {
		ACCOUNTING_RECEIVE_PAYMENT: 'ACCOUNTING_MODULE_RECEIVE_PAYMENT',
		ACCOUNTING_PAYMENT_MADE: 'ACCOUNTING_MODULE_PAYMENT_MADE',
	},
	INVENTORY: {
		INVENTORY_MODULE_PRODUCT_ITEM_INVENTORY_LOOKUP: 'INVENTORY_MODULE_PRODUCT_ITEM_INVENTORY_LOOKUP',
	},
	HEADER: {
		OPEN_MANAGE_UPDATES: 'OPEN_MANAGE_UPDATES',
		REV_360_OPEN_EDIT_PROFILE: 'REV_360_OPEN_EDIT_PROFILE',
	},
	SECURITY: {
		SECURITY_SESSION_STARTED: 'SECURITY_SESSION_STARTED',
		SECURITY_SESSION_STOPPING: 'SECURITY_SESSION_STOPPING',
		SECURITY_SESSION_LOCKED: 'SECURITY_SESSION_LOCKED',
		SECURITY_SESSION_UNLOCKED: 'SECURITY_SESSION_UNLOCKED',
		SECURITY_SESSION_IS_NOT_AUTHORIZED: 'SECURITY_SESSION_IS_NOT_AUTHORIZED',
		SECURITY_SESSION_IS_LOCKED: 'SECURITY_SESSION_IS_LOCKED',
		SECURITY_SESSION_UPDATED: 'SECURITY_SESSION_UPDATED',
	},
	MODAL: {
		MODAL_OPENED: 'MODAL_OPENED',
		MODAL_CLOSED: 'MODAL_CLOSED',
	},
	MESSAGING: {
		MESSAGING_MODULE_NEW_MESSAGE: 'MESSAGING_MODULE_NEW_MESSAGE',
		MESSAGING_MODULE_OPEN_MESSAGE: 'MESSAGING_MODULE_OPEN_MESSAGE',
	},
	NOTES: {
		SYSTEM_USER: 'SYSTEM',
		OPEN_NEW_NOTE: 'OPEN_NEW_NOTE',
		OPEN_CURRENT_NOTE: 'OPEN_CURRENT_NOTE',
		REFRESH_NOTE_LIST: 'REFRESH_NOTE_LIST',
	},
	PATIENTS: {
		PATIENTS_MODULE_SCANNED_DOCUMENT_UPLOADED: 'PATIENTS_MODULE_SCANNED_DOCUMENT_UPLOADED',
		PATIENTS_MODULE_OPEN_PATIENT: 'PATIENTS_MODULE_OPEN_PATIENT',
		PATIENTS_MODULE_OPEN_INVOICE: 'PATIENTS_MODULE_OPEN_INVOICE',
		PATIENT_MODULE_OPEN_APPOINTMENT: 'PATIENT_MODULE_OPEN_APPOINTMENT',
		PATIENT_MODULE_OPEN_ENCOUNTER_NO_HTML: 'PATIENT_MODULE_OPEN_ENCOUNTER_NO_HTML',
		PATIENT_MODULE_CLOSE_ENCOUNTER: 'PATIENT_MODULE_CLOSE_ENCOUNTER',
		START_PATIENT_ENCOUNTER_FROM_APPOINTMENT: 'START_PATIENT_ENCOUNTER_FROM_APPOINTMENT',
		COMPLETE_PATIENT_ENCOUNTER: 'COMPLETE_PATIENT_ENCOUNTER',
		PATIENTS_MODULE_REFRESH_PERSON_DIAGNOSES: 'PATIENTS_MODULE_REFRESH_PERSON_DIAGNOSES',
		PATIENTS_MODULE_SUMMARY_DASHBOARD_SELECT_TAB_FOR_PATIENT: 'PATIENTS_MODULE_SUMMARY_DASHBOARD_SELECT_TAB_FOR_PATIENT',
		PATIENT_SUMMARY_DASHBOARD_CLOSE_TAB_FOR_PATIENT: 'PATIENT_SUMMARY_DASHBOARD_CLOSE_TAB_FOR_PATIENT',
		PATIENTS_SUMMARY_DASHBOARD_SEARCH: 'PATIENTS_SUMMARY_DASHBOARD_SEARCH',
		PATIENTS_SUMMARY_DASHBOARD_SELECT_TAB_FOR_PATIENT: 'PATIENTS_SUMMARY_DASHBOARD_SELECT_TAB_FOR_PATIENT',
		PATIENTS_SUMMARY_DASHBOARD_CLOSE_TAB_FOR_PATIENT: 'PATIENTS_SUMMARY_DASHBOARD_CLOSE_TAB_FOR_PATIENT',
		PATIENTS_SUMMARY_DASHBOARD_ADD_NEW_PATIENT: 'PATIENTS_SUMMARY_DASHBOARD_ADD_NEW_PATIENT',
		PATIENTS_SUMMARY_DASHBOARD_NEW_PATIENT_ADDED: 'PATIENTS_SUMMARY_DASHBOARD_NEW_PATIENT_ADDED',
		PATIENTS_DASHBOARD_PATIENT_SUMMARY_SELECT: 'PATIENTS_DASHBOARD_PATIENT_SUMMARY_SELECT',
		PATIENTS_DASHBOARD_OPTICAL_SUMMARY_SELECT: 'PATIENTS_DASHBOARD_OPTICAL_SUMMARY_SELECT',
		PATIENTS_DASHBOARD_ALERTS_SELECT: 'PATIENTS_DASHBOARD_ALERTS_SELECT',
		PATIENTS_DASHBOARD_AMENDMENTS_SELECT: 'PATIENTS_DASHBOARD_AMENDMENTS_SELECT',
		PATIENTS_DASHBOARD_IMPLANTABLE_DEVICES_SELECT: 'PATIENTS_DASHBOARD_IMPLANTABLE_DEVICES_SELECT',
		PATIENTS_DASHBOARD_EXTERNAL_DATA_SELECT: 'PATIENTS_DASHBOARD_EXTERNAL_DATA_SELECT',
		PATIENTS_DASHBOARD_CONNECT_SELECT: 'PATIENTS_DASHBOARD_CONNECT_SELECT',
		PATIENTS_DASHBOARD_REFERRALS_SELECT: 'PATIENTS_DASHBOARD_REFERRALS_SELECT',

		PATIENTS_DASHBOARD_SERVICE_HISTORY_SELECT: 'PATIENTS_DASHBOARD_SERVICE_HISTORY_SELECT',
		PATIENTS_DASHBOARD_IMMUNIZATIONS_SELECT: 'PATIENTS_DASHBOARD_IMMUNIZATIONS_SELECT',
		PATIENTS_DASHBOARD_SURGERY_HISTORY_SELECT: 'PATIENTS_DASHBOARD_SURGERY_HISTORY_SELECT',
		PATIENTS_DASHBOARD_INTERVIEW_HISTORY_SELECT: 'PATIENTS_DASHBOARD_INTERVIEW_HISTORY_SELECT',
		PATIENTS_DASHBOARD_TASKS_SELECT: 'PATIENTS_DASHBOARD_TASKS_SELECT',
		PATIENTS_MODULE_DISPLAY_COMMUNICATION_DOCUMENT: 'PATIENTS_MODULE_DISPLAY_COMMUNICATION_DOCUMENT',
		ACCOUNT_SUMMARY_UPDATED: 'ACCOUNT_SUMMARY_UPDATED',
		CONNECT_SUMMARY_UPDATED: 'CONNECT_SUMMARY_UPDATED',
		DIAGNOSIS_SUMMARY_UPDATED: 'DIAGNOSIS_SUMMARY_UPDATED',
		DIAGNOSIS_SUMMARY_UPDATED_PATIENT_ID: 'DIAGNOSIS_SUMMARY_UPDATED_PATIENT_ID',
		FAMILY_SUMMARY_UPDATED: 'FAMILY_SUMMARY_UPDATED',
		HISTORY_SUMMARY_UPDATED: 'HISTORY_SUMMARY_UPDATED',
		INSURANCE_SUMMARY_UPDATED: 'INSURANCE_SUMMARY_UPDATED',
		MEDICAL_SUMMARY_UPDATED: 'MEDICAL_SUMMARY_UPDATED',
		OPTICAL_SUMMARY_UPDATED: 'OPTICAL_SUMMARY_UPDATED',
		OPTICAL_SUMMARY_REFRACTIONS_UPDATED: 'OPTICAL_SUMMARY_REFRACTIONS_UPDATED',
		RECALL_SUMMARY_UPDATED: 'RECALL_SUMMARY_UPDATED',
		NOTES_SUMMARY_UPDATED: 'NOTES_SUMMARY_UPDATED',
		RX_SUMMARY_UPDATED_PERSON_ID: 'RX_SUMMARY_UPDATED_PERSON_ID',
		RX_SUMMARY_UPDATED_PATIENT_ID: 'RX_SUMMARY_UPDATED_PATIENT_ID',
		TASK_SUMMARY_UPDATED: 'TASK_SUMMARY_UPDATED',
		PATIENT_UPDATED: 'PATIENT_UPDATED',
		PATIENT_UPDATED_HTML: 'PATIENT_UPDATED_HTML',
		PATIENTS_MODULE_NAVIGATE_TO_PATIENT_VIEW: 'PATIENTS_MODULE_NAVIGATE_TO_PATIENT_VIEW',
		PATIENTS_MODULE_CLOSE_MODULE: 'PATIENTS_MODULE_CLOSE_MODULE',
		PATIENTS_MODULE_OPEN_QUICK_VIEW: 'PATIENTS_MODULE_OPEN_QUICK_VIEW',
		PATIENTS_MODULE_CONVERT_DIAGNOSIS_CODES: 'PATIENTS_MODULE_CONVERT_DIAGNOSIS_CODES',
		PATIENT_TAB_MODULE_SELECTED: 'PATIENT_TAB_MODULE_SELECTED',
		PATIENTS_MODULE_VIEW_HTML_CCDA: 'PATIENTS_MODULE_VIEW_HTML_CCDA',
	},
	SCHEDULE: {
		SCHEDULE_MODULE_OPEN_APPOINTMENT: 'SCHEDULE_MODULE_OPEN_APPOINTMENT',
		SCHEDULE_MODULE_ADD_APPOINTMENT: 'SCHEDULE_MODULE_ADD_APPOINTMENT',
		SCHEDULE_MODULE_ADD_TO_WAITLIST: 'SCHEDULE_MODULE_ADD_TO_WAITLIST',
		SCHEDULE_MODULE_VIEW_WAITLIST: 'SCHEDULE_MODULE_VIEW_WAITLIST',
		SCHEDULE_MODULE_ADD_APPOINTMENT_FOR_PATIENT: 'SCHEDULE_MODULE_ADD_APPOINTMENT_FOR_PATIENT',
		SCHEDULE_REFRESH_TODAYS_APPOINTMENTS: 'SCHEDULE_REFRESH_TODAYS_APPOINTMENTS',
	},
	TASKS: {
		TASKS_MODULE_ADD_TASK: 'TASKS_MODULE_ADD_TASK',
		TASKS_MODULE_OPEN_TASK: 'TASKS_MODULE_OPEN_TASK',
		TASKS_MODULE_ADD_DRAFT_TASK: 'TASKS_MODULE_ADD_DRAFT_TASK',
		TASKS_MODULE_OPEN_PATIENT_LETTER: 'TASKS_MODULE_OPEN_PATIENT_LETTER',
		TASKS_MODULE_MARK_TASK_READ: 'TASKS_MODULE_MARK_TASK_READ',
	},
	REPORTS: {
		REPORTS_MODULE_MIPS_OPEN: 'REPORTS_MODULE_MIPS_OPEN',
		REPORTS_MODULE_MIPS_ACI_OPEN: 'REPORTS_MODULE_MIPS_ACI_OPEN',
	},
	MANUAL_POLL: {
		MANUAL_POLL_TOTALS: 'MANUAL_POLL_TOTALS',
		MANUAL_POLL_MESSAGE_TOTALS: 'MANUAL_POLL_MESSAGE_TOTALS',
	},
	NAVIGATION: {
		NAVIGATION_REQUESTED: 'NAVIGATION_REQUESTED',
		NAVIGATE_HOME: 'NAVIGATE_HOME',
	},
	LOCATION: {
		LOCATION_CHANGE: 'LOCATION_CHANGE',
		TRIGGER_LEGACY_LOCATION_CHANGE: 'TRIGGER_LEGACY_LOCATION_CHANGE',
		PATIENT_LOCATION_LIMIT: 25,
	},
	APPLICATION: {
		APPLICATION_SHOWN: 'APPLICATION_SHOWN',
		CHANGE_ROUTE: 'CHANGE_ROUTE',
		ADMINISTRATION_FLEX_MODULE_SELECTED: 'ADMINISTRATION_MODULE_SELECTED',
		REPORTS_FLEX_MODULE_SELECTED: 'REPORTS_MODULE_SELECTED',
	},
	ORDERS: {
		OPEN_ORDER_TO_FLEX: 'openOrderTOFlex',
		CLOSE_ORDER_TO_FLEX: 'closeOrderTOFlex',
		ORDERS_MODULE_SELECT_ORDER_SEARCH_TAB_FROM_FLEX: 'ORDERS_MODULE_SELECT_ORDER_SEARCH_TAB_FROM_FLEX',
		ORDERS_MODULE_SELECT_ORDER_FROM_FLEX: 'ORDERS_MODULE_SELECT_ORDER_FROM_FLEX',
		ORDERS_MODULE_CLOSE_ORDER_FROM_FLEX: 'ORDERS_MODULE_CLOSE_ORDER_FROM_FLEX',
		ORDERS_OPEN_CREATE_ORDER_MODAL: 'ORDERS_OPEN_CREATE_ORDER_MODAL',
	},
	KEYS: {
		ENTER: 13,
		F: 70,
		LEFT_ARROW: 37,
		UP_ARROW: 38,
		RIGHT_ARROW: 39,
		DOWN_ARROW: 40,
	},
	ADMINISTRATION: {
		OPEN_LOCATION: 'ADMINISTRATION_MODULE_OPEN_LOCATION',
		ADMIN_MODULE_REFRESH_PRACTICE_DOCUMENT_TEMPLATES: 'ADMIN_MODULE_REFRESH_PRACTICE_DOCUMENT_TEMPLATES',
		REFRESH_PRINTER_TABLE: 'REFRESH_PRINTER_TABLE',
		NEW_PRINTER_ADDED: 'NEW_PRINTER_ADDED',
		OPEN_BARCODE_LABEL_PRINTER: 'ADMINISTRATION_MODULE_BARCODE_LABEL_PRINTER',
		CLOSE_PRINT_FORM: 'CLOSE_PRINT_FORM',
		AUTO_DIAGNOSIS_OPEN: 'ADMINISTRATION_MODULE_AUTO_DIAGNOSIS_OPEN',
		COMMUNICATION_TEMPLATE_ADD: 'ADMINISTRATION_MODULE_COMMUNICATION_TEMPLATE_ADD',
		COMMUNICATION_TEMPLATE_CONVERT: 'ADMINISTRATION_MODULE_COMMUNICATION_TEMPLATE_CONVERT',
		COMMUNICATION_TEMPLATE_EDIT: 'ADMINISTRATION_MODULE_COMMUNICATION_TEMPLATE_EDIT',
		COMMUNICATION_TEMPLATE_COPY: 'ADMINISTRATION_MODULE_COMMUNICATION_TEMPLATE_COPY',
		COMMUNICATION_TEMPLATE_PREVIEW: 'ADMINISTRATION_MODULE_COMMUNICATION_TEMPLATE_PREVIEW',
		IMPORT_QRDA: 'ADMINISTRATION_MODULE_IMPORT_QRDA',
		CCDA_DISPLAY: 'ADMINISTRATION_MODULE_CCDA_DISPLAY',
		ADMINISTRATION_MODULE_CCDA_MODAL_OPEN: 'ADMINISTRATION_MODULE_CCDA_MODAL_OPEN',
		CCDA_EXPORT_LATER: 'CCDA_EXPORT_LATER',
		CCDA_EXPORT_NOW: 'CCDA_EXPORT_NOW',
		CCDA_SCHEDULE_MODAL_OPEN: 'ADMINISTRATION_MODULE_LIST_CCDA',
		ADMIN_MODULE_HIDE_LEGACY: 'ADMIN_MODULE_HIDE_LEGACY',
	},
	DOCKABLEMODAL: {
		CLOSE_DOCKABLE_MODAL: 'CLOSE_DOCKABLE_MODAL',
	},
	FILE_STRUCTURE: {
		OPEN_TO_NODE: 'OPEN_TO_NODE',
	},
	SCANNER: {
		SCANNER_MODULE_SCAN_DOCUMENT: 'SCANNER_MODULE_SCAN_DOCUMENT',
	},
	IMAGING: {
		LAUNCH_IMAGING: 'IMAGING_LAUNCHER_MODULE_LAUNCH_IMAGING',
		IMAGING_APPLICATION_TAB_READY: 'IMAGING_APPLICATION_TAB_READY',
		IMAGING_APPLICATION_TAB_NOT_READY: 'IMAGING_APPLICATION_TAB_NOT_READY',
		IMAGING_APPLICATION_TAB_OPEN_FILE: 'IMAGING_APPLICATION_TAB_OPEN_FILE',
	},
	REVMONKEY: {
		LOGIN: 'REVMONKEY_LOGIN',
	},
	AGREEMENTS: {
		CUSTOMER_AGREEMENTS_EXPIRED: 'CUSTOMER_AGREEMENTS_EXPIRED',
		SHOW_CUSTOMER_AGREEMENTS: 'SHOW_CUSTOMER_AGREEMENTS',
		AGREEMENT_ACCEPTED: 'AGREEMENT_ACCEPTED',
	},
};

export const HIT_PMS_HTML_PERMISSIONS = {
	RESOURCE_PATIENT_VIEW : 1,
	RESOURCE_PATIENT_VIEW_SSN : 2,
	RESOURCE_PATIENT_NOTE_OVERRIDE : 3,
	RESOURCE_PATIENT_ADD_INSURANCE_COMPANY : 5,
	RESOURCE_ENCOUNTER_VIEW_CDS_INTERVENTIONS : 10,
	RESOURCE_SCHEDULE_VIEW : 101,
	RESOURCE_SCHEDULE_CREATE : 102,
	RESOURCE_SCHEDULE_CANCEL : 103,
	RESOURCE_SCHEDULE_RESCHEDULE : 104,
	RESOURCE_SCHEDULE_SLOT_CREATE : 105,
	RESOURCE_SCHEDULE_SLOT_EDIT : 106,
	RESOURCE_SCHEDULE_SLOT_DELETE : 107,
	RESOURCE_SCHEDULE_BUSY_TIME_CREATE : 108,
	RESOURCE_SCHEDULE_BUSY_TIME_EDIT : 109,
	RESOURCE_SCHEDULE_BUSY_TIME_DELETE : 110,
	RESOURCE_SCHEDULE_OFF_CLOSED_CREATE : 111,
	RESOURCE_SCHEDULE_OFF_CLOSED_EDIT : 112,
	RESOURCE_SCHEDULE_OFF_CLOSED_DELETE : 113,
	RESOURCE_SCHEDULE_EMP_HOURS_CREATE : 114,
	RESOURCE_SCHEDULE_EMP_HOURS_EDIT : 115,
	RESOURCE_SCHEDULE_EMP_HOURS_DELETE : 116,
	RESOURCE_SCHEDULE_REPORTS : 117,
	RESOURCE_SCHEDULE_EMP_HOURS_OWN : 118,

	RESOURCE_ACCOUNTING_VIEW : 201,
	RESOURCE_ACCOUNTING_INVOICE_CREATE : 202,
	RESOURCE_ACCOUNTING_INVOICE_VOID : 203,
	RESOURCE_ACCOUNTING_PAYMENT_RECEIVE : 204,
	RESOURCE_ACCOUNTING_PAYMENT_VOID : 205,
	RESOURCE_ACCOUNTING_DISCOUNT : 206,
	RESOURCE_ACCOUNTING_WRITEOFF : 207,
	RESOURCE_ACCOUNTING_REFUND : 208,
	RESOURCE_ACCOUNTING_CREDIT : 209,
	RESOURCE_ACCOUNTING_CREDIT_VOID : 212,
	RESOURCE_ACCOUNTING_REFUND_VOID : 213,
	RESOURCE_ACCOUNTING_STATEMENT_SEND : 214,
	RESOURCE_ACCOUNTING_ITEM_REMOVE : 215,
	RESOURCE_ACCOUNTING_EDIT_INV_FIN_CHG : 226,
	RESOURCE_ACCOUNTING_UNAUTH_FIN_CHG_INV : 227,
	RESOURCE_ACCOUNTING_INVOICE_ENCOUNTER : 229,
	RESOURCE_ACCOUNTING_INVOICE_DATE : 230,
	RESOURCE_ACCOUNTING_INVOICE_PROVIDER : 231,
	RESOURCE_ACCOUNTING_BILLING : 232,

	RESOURCE_INVENTORY_VIEW : 301,
	RESOURCE_INVENTORY_EDIT_PRICE : 302,
	RESOURCE_INVENTORY_EDIT_QUANTITY : 303,
	RESOURCE_INVENTORY_COPY_PRODUCTS : 304,
	RESOURCE_INVENTORY_COPY_SERVICES : 305,
	RESOURCE_INVENTORY_UPDATE_PROPERTIES : 306,
	RESOURCE_INVENTORY_UPDATE_PRICING : 307,
	RESOURCE_INVENTORY_EDIT : 308,
	RESOURCE_INVENTORY_VIEW_ACTUAL_OR_UNIT_COST : 309,

	RESOURCE_ORDERS_VIEW : 401,
	RESOURCE_ORDERS_CREATE : 402,
	RESOURCE_ORDERS_CANCEL : 403,
	RESOURCE_ORDERS_EDIT : 404,
	RESOURCE_ORDERS_CHANGE : 405,
	RESOURCE_ORDERS_COMPLETE : 406,
	RESOURCE_ORDERS_DUPLICATE : 407,
	RESOURCE_ORDERS_STATUS_UPDATE : 408,
	RESOURCE_ORDERS_REMAKE : 409,
	RESOURCE_ORDERS_MARK_NON_BILLABLE : 410,
	RESOURCE_ORDERS_STATUS_EDIT : 411,

	RESOURCE_MARKETING_VIEW : 501,
	RESOURCE_REPORTS_PATIENTS : 511,
	RESOURCE_MARKETING_PATIENT_SEARCH_CREATE : 502,
	RESOURCE_MARKETING_PATIENT_SEARCH_EDIT : 503,
	RESOURCE_MARKETING_PATIENT_SEARCH_DELETE : 504,
	RESOURCE_MARKETING_PATIENT_SEARCH_EXECUTE : 505,
	RESOURCE_REPORTS_SCHEDULE : 506,
	RESOURCE_REPORTS_APPOINTMENTS : 512,
	RESOURCE_REPORTS_APPOINTMENT_SEARCH : 513,
	RESOURCE_REPORTS_ENCOUNTERS : 514,
	RESOURCE_REPORTS_ENCOUNTER_SEARCH : 515,
	RESOURCE_REPORTS_SCHEDULE_CUSTOM : 516,
	RESOURCE_ACCOUNTING_REPORTS : 210,
	RESOURCE_ACCOUNTING_REPORT_LEDGER_POSTING : 211,
	RESOURCE_ACCOUNTING_REPORT_UNASSIGNED_ITEMS : 216,
	RESOURCE_ACCOUNTING_REPORT_AGING : 217,
	RESOURCE_ACCOUNTING_REPORT_DEPOSITS : 218,
	RESOURCE_ACCOUNTING_REPORT_PAYMENT_SEARCH : 228,
	RESOURCE_ACCOUNTING_REPORT_LEDGER : 219,
	RESOURCE_ACCOUNTING_REPORT_LEDGER_DAILY : 220,
	RESOURCE_ACCOUNTING_REPORT_SALES : 221,
	RESOURCE_ACCOUNTING_REPORT_RECEIPTS : 222,
	RESOURCE_ACCOUNTING_REPORT_REFUNDS : 223,
	RESOURCE_ACCOUNTING_SEARCH_INVOICES : 224,
	RESOURCE_ACCOUNTING_SEARCH_INVOICE_ITEMS : 225,
	RESOURCE_REPORTS_ORDERS : 507,
	RESOURCE_REPORTS_TASKS : 508,
	RESOURCE_REPORTS_INVENTORY : 509,
	RESOURCE_REPORTS_ADMIN : 510,

	RESOURCE_ADMIN_VIEW : 601,
	RESOURCE_ADMIN_GENERAL : 602,
	RESOURCE_ADMIN_EMPLOYEE : 603,
	RESOURCE_ADMIN_DATA : 604,
	RESOURCE_ADMIN_TASKS : 605,
	RESOURCE_ADMIN_ENCOUNTERS : 606,
	RESOURCE_ADMIN_DOCUMENTS : 607,
	RESOURCE_ADMIN_COMMUNICATION : 608,
	RESOURCE_ADMIN_TRIGGERS : 612,
	RESOURCE_ADMIN_VENDORS : 609,
	RESOURCE_ADMIN_PATIENTS : 610,
	RESOURCE_ADMIN_ACCOUNTING : 611,

	RESOURCE_CONNECT_VIEW : 801,
	RESOURCE_CONNECT_VIEW_DASHBOARD : 802,
	RESOURCE_CONNECT_VIEW_SETTINGS : 803,
	RESOURCE_CONNECT_EDIT_SETTINGS : 804,
	RESOURCE_CONNECT_COPY_LOCATIONS : 805,
};

export const HIT_PMS_HTML_PREFERENCES = {
	DATE_TIME_DISPLAY_SECONDS: { name: 'date.time.display.seconds', defaultValue: 'false' },
	SCHEDULE_FILTER_DEFAULT: { name: 'schedule.filter.default', defaultValue: '' },
	SCHEDULE_ENABLE_VALIDATION: { name: 'schedule.validation.enabled', defaultValue: 'false' },
	SCHEDULE_GROUPING_ENABLED: { name: 'schedule.grouping.enabled', defaultValue: 'false' },
	SCHEDULE_MY_PATIENTS_ONLY: { name: 'schedule.my.patients.only', defaultValue: 'false' },
	SCHEDULEPOD_TAB: { name: 'schedulepod.tab', defaultValue: 'all',
		values: { SCHEDULEPOD_TAB_ALL: 'all', SCHEDULEPOD_TAB_UPCOMING: 'upcoming', SCHEDULEPOD_TAB_INPROGRESS: 'inprogress', SCHEDULEPOD_TAB_COMPLETED: 'completed' },
	},
	ACCOUNTING_INV_HIDE_TIN: { name: 'accounting.inv.hide.tin', defaultValue: 'false' },
	ACCOUNTING_INV_HIDE_ADJUSTMENTS: { name: 'accounting.inv.hide.adjustments', defaultValue: 'false' },
	ACCOUNTING_REP_HIDE_CHART: { name: 'accounting.rep.hide.chart', defaultValue: 'false' },
	ACCOUNTING_TRANSFER_BY_ITEM: { name: 'accounting.transfer.by.item', defaultValue: 'false' },
	ACCOUNTING_ALLOW_SPLIT_BUTTON: { name: 'accounting.allow.split.button', defaultValue: 'false' },
	ACCOUNTING_ALLOW_DISCOUNT_BUTTON: { name: 'accounting.allow.discount.button', defaultValue: 'false' },
	ACCOUNTING_ALLOW_PORTION_BUTTON: { name: 'accounting.allow.portion.button', defaultValue: 'false' },
	ACCOUNTING_ALLOW_REFUND_BUTTON: { name: 'accounting.allow.refund.button', defaultValue: 'false' },
	ACCOUNTING_ALLOW_WALK_INS: { name: 'accounting.allow.walk.ins', defaultValue: 'false' },
	ACCOUNTING_STATEMENTS_FORMAT: { name: 'accounting.statements.format', defaultValue: '1',
		values: { PDF: '1', XML: '2' },
	},
	ACCOUNTING_ERA_AUTOPOSTING: { name: 'accounting.era.autoposting', defaultValue: 'false' },
	PRESCRIPTION_MED_EXP_MONTHS: { name: 'prescription.med.exp.months', defaultValue: '12' },
	PRESCRIPTION_CL_EXP_MONTHS: { name: 'prescription.cl.exp.months', defaultValue: '12' },
	PRESCRIPTION_SPEC_EXP_MONTHS: { name: 'prescription.spec.exp.months', defaultValue: '12' },
	PRESCRIPTION_CL_CUSTOM_MSG: { name: 'prescription.cl.custom.msg', defaultValue: '' },
	PRESCRIPTION_SPEC_CUSTOM_MSG: { name: 'prescription.spec.custom.msg', defaultValue: '' },
	PRESCRIPTION_MED_INCLUDE_NPI: { name: 'prescription.med.include.npi', defaultValue: 'false' },
	PRESCRIPTION_MED_PRINT_FILE: { name: 'prescription.med.print.file', defaultValue: '' ,
		values: { DEFAULT: '', SUB_LEFT: 'MedRx_SubstitutionLeft.jasper', SUB_RIGHT: 'MedRx_SubstitutionRight.jasper', NJPB: 'MedRx_NewJerseyPrescriptionBlank.jasper'},
	},
	PRESCRIPTION_SPEC_PRINT_FILE: { name: 'prescription.spec.print.file', defaultValue: '',
		values: { DEFAULT: '', CL_APPROVAL: 'EyeglassRx_ContactApproval.jasper', NJPB: 'EyeglassRx_NewJerseyPrescriptionBlank.jasper', NJPB_NONGRID: 'EyeglassRx_NewJerseyPrescriptionBlankNonGrid.jasper'},
	},
	PRESCRIPTION_CL_PRINT_FILE: { name: 'prescription.cl.print.file', defaultValue: '',
		values: { DEFAULT: '', NJPB: 'ContactLensRx_NewJerseyPrescriptionBlank.jasper'},
	},
	STEPPERS_TEXT_ENABLED: { name: 'steppers.text.enabled', defaultValue: 'false' },
	ADDRESS_PRINT_UPPER_CASE: { name: 'address.print.upper.case', defaultValue: 'false' },
	REMOVE_USERNAME_ON_LOGOUT: { name: 'remove.username.on.logout', defaultValue: 'false' },
	DOCUMENTS_DISPLAY_DETAILED_VIEW: { name: 'documents.display.detailed.view', defaultValue: 'false' },
	IMAGING_AUTOMATIC_OPEN_ENABLED: { name: 'imaging.automatic.open.enabled', defaultValue: 'false' },
	PAT_SUMMARY_INCLUDE_PAT_BENEFITS: { name: 'pat.summ.include.pat.benefits', defaultValue: 'false' },
	PAT_SUMMARY_ADDL_MESSAGE: { name: 'pat.summ.addl.message', defaultValue: '' },
	ORDER_ENABLE_APPROVAL_VALIDATION: { name: 'order.approval.validation.enabled', defaultValue: 'false' },
	ORDER_ENABLE_ORDER_COSTS: { name: 'order.costs.enabled', defaultValue: 'false' },
	ORDER_PD_VALIDATION_RULE: { name: 'order.pd.validation.rule', defaultValue: '',
		values: {
			NONE: 'order.pd.validation.rule.none',
			MONOCULAR_OR_BINOCULAR: 'order.pd.validation.rule.monocular.or.binocular',
			MONOCULAR: 'order.pd.validation.rule.monocular',
		},
	},
	ORDER_EYEGLASS_MATERIAL_REQUIRED: { name: 'order.eyeglass.material.required', defaultValue: 'false' },
	ORDER_EYEGLASS_MOUNTING_TYPE_REQUIRED: { name: 'order.eyeglass.mountingType.required', defaultValue: 'false' },
	ORDER_EYEGLASS_FRAME_SOURCE_INDICATOR_REQUIRED: { name: 'order.eyeglass.frameSourceIndicator.required', defaultValue: 'false' },
	ORDER_EYEGLASS_FRAME_SOURCE_REQUIRED: { name: 'order.eyeglass.frameSource.required', defaultValue: 'false' },
	ORDER_EYEGLASS_LAB_REQUIRED: { name: 'order.eyeglass.lab.required', defaultValue: 'false' },
	ENCOUNTER_FORWARD_TEST_NOTES: { name: 'encounter.forward.test.notes', defaultValue: 'false' },
	ENCOUNTER_TEST_HISTORY_DISPLAY_GRID: { name: 'encounter.test.history.grid', defaultValue: 'false' },
	ENCOUNTER_ROLES_ENABLED: { name: 'encounter.roles.enabled', defaultValue: 'false' },
	ENCOUNTER_INCL_LANG_ON_PRINTOUT: { name: 'encounter.incl.language.on.printout', defaultValue: 'false' },
	ENCOUNTER_INCL_RACE_ON_PRINTOUT: { name: 'encounter.incl.race.on.printout', defaultValue: 'false' },
	ENCOUNTER_INCL_INTERPRETER_ON_PRINTOUT: { name: 'encounter.incl.interpreter.on.printout', defaultValue: 'false' },
	FOCUS_BARCODE_SEARCH_FRAMES: { name: 'focus.barcode.search.frames', defaultValue: 'false' },
	FOCUS_BARCODE_SEARCH_EYEGLASSLENS: { name: 'focus.barcode.search.eyeglasslens', defaultValue: 'false' },
	FOCUS_BARCODE_SEARCH_LENSTREATMENTS: { name: 'focus.barcode.search.lenstreatments', defaultValue: 'false' },
	FOCUS_BARCODE_SEARCH_CONTACTLENS: { name: 'focus.barcode.search.contactlens', defaultValue: 'false' },
	FOCUS_BARCODE_SEARCH_MISC: { name: 'focus.barcode.search.misc', defaultValue: 'false' },
	BARCODE_PRINTER_INTEGRATION: { name: 'barcode.printer.integration', defaultValue: 'false' },
	PRODUCT_LABEL_LAST_LABEL_ID: { name: 'product.label.last.label.id', defaultValue: '' },
	PRODUCT_LABEL_LAST_ELEMENT_IDS: { name: 'product.label.last.element.ids', defaultValue: '' },
	PRODUCT_LABEL_LAST_BARCODE_SOURCE: { name: 'product.label.last.barcode.source', defaultValue: '' },
	PRODUCT_LABEL_LAST_FORMAT_PRICE: { name: 'product.label.last.format.price', defaultValue: '' },
	PRODUCT_LABEL_LAST_SHEET_ROW: { name: 'product.label.last.sheet.row', defaultValue: '' },
	PRODUCT_LABEL_LAST_SHEET_COLUMN: { name: 'product.label.last.sheet.column', defaultValue: '' },
	AUDIT_REPORT_PAGING_ENABLED: { name: 'audit.report.paging.enabled', defaultValue: 'true' },
	PFSH_FAMILY_HEALTH_HISTORY_ENABLED: { name: 'pfsh.family.health.history.enabled', defaultValue: 'false' },
	TOAST_MESSAGES_DURATION: { name: 'toast.messages.duration', defaultValue: '3' },
	MED_SCREEN_AUTO_WIDTH: { name: 'med.screen.auto.width', defaultValue: '1280' },
	MED_SCREEN_ORIENTATION: { name: 'med.screen.orientation', defaultValue: 'automatic',
		values: { AUTOMATIC: 'automatic', VERTICAL: 'vertical', HORIZONTAL: 'horizontal'},
	},
	EMAIL_IS_REQUIRED: {name: 'demographics.email.required', defaultValue: 'false'},
	ZEISS_FORUM_ENABLED: { name: 'zeiss.forum.enabled', defaultValue: 'false' },
	REV_INTEGRATOR_EXPORT_ENABLED: { name: 'revIntegrator.export.enabled', defaultValue: 'false' },
	OPTOVUE_REVUE_EXPORT_ENABLED: { name: 'optovue.revue.export.enabled', defaultValue: 'false' },
	OPTOMAP_EXPORT_ENABLED: {name: 'optomap.export.enabled', defaultValue: 'false'},
	VISIOFFICE_MEASUREMENTS_ENABLED: { name: 'visioffice.measurements.enabled', defaultValue: 'false' },
	PROVIDER_ALWAYS_USE_SIGNATURE_IMAGE: { name: 'provider.always.use.signature.image', defaultValue: 'true' },
	SCANNING_ENABLED: { name: 'scanning.enabled', defaultValue: 'true' },
	PATIENT_POD_DEMOGRAPHICS_ENABLED: { name: 'patient.pod.demographics.enabled', defaultValue: 'true' },
	PATIENT_POD_FAMILYCONTACTS_ENABLED: { name: 'patient.pod.familycontacts.enabled', defaultValue: 'true' },
	PATIENT_POD_RX_ENABLED: { name: 'patient.pod.rx.enabled', defaultValue: 'true' },
	PATIENT_POD_EXAMHISTORY_ENABLED: { name: 'patient.pod.examhistory.enabled', defaultValue: 'true' },
	PATIENT_POD_ACCOUNT_ENABLED: { name: 'patient.pod.account.enabled', defaultValue: 'true' },
	PATIENT_POD_NOTES_ENABLED: { name: 'patient.pod.notes.enabled', defaultValue: 'true' },
	PATIENT_POD_INSURANCE_ENABLED: { name: 'patient.pod.insurance.enabled', defaultValue: 'true' },
	PATIENT_POD_DIAGNOSISHISTORY_ENABLED: { name: 'patient.pod.diagnosishistory.enabled', defaultValue: 'true' },
	PATIENT_POD_RECALL_ENABLED: { name: 'patient.pod.recall.enabled', defaultValue: 'true' },
	PATIENT_POD_LABORDERS_ENABLED: { name: 'patient.pod.laborders.enabled', defaultValue: 'true' },
	PATIENT_POD_OPTICALORDERS_ENABLED: { name: 'patient.pod.opticalorders.enabled', defaultValue: 'true' },
	PATIENT_POD_TASKS_ENABLED: { name: 'patient.pod.tasks.enabled', defaultValue: 'true' },
	PATIENT_POD_CONNECT_ENABLED: { name: 'patient.pod.connect.enabled', defaultValue: 'true' },
	PATIENT_POD_FAMILY_CONTACT_COMP_ENC_COLORS_ENABLED: { name: 'patient.pod.family.contacts.comp.enc.colors.enabled', defaultValue: 'false' },
	PATIENT_POD_FAMILY_CONTACT_COMP_ENC_RANGE_OK: { name: 'patient.pod.family.contacts.comp.enc.range.ok', defaultValue: '6' },
	PATIENT_POD_FAMILY_CONTACT_COMP_ENC_RANGE_LOW: { name: 'patient.pod.family.contacts.comp.enc.range.low', defaultValue: '12' },
	PATIENT_POD_FAMILY_CONTACT_COMP_ENC_RANGE_HIGH: { name: 'patient.pod.family.contacts.comp.enc.range.high', defaultValue: '24' },
	PRACTICE_INVOICE_FREEZE_DATE: { name: 'practice.invoice.freeze.date', defaultValue: ''},
	AUTOMATOR_VSP_ELIGIBILITY_ENABLED: { name: 'automator.vsp.eligibility.enabled', defaultValue: 'false'},
	REVASSISTANT_RX_LENS_DETAILS_ENABLED: { name: 'revassistant.rx.lens.details.enabled', defaultValue: 'false'},
	INVENTORY_MANAGEMENT_ENABLED: { name: 'inventory.management.enabled', defaultValue: 'false'},
	INVENTORY_QUANTITY_REASON_REQUIRED: { name: 'inventory.quantity.reason.required', defaultValue: 'false'},
	DEMOGRAPHICS_ALTERNATEID_ENABLED: { name: 'demographics.alternateId.enabled', defaultValue: 'false'},
	DEMOGRAPHICS_ADDRESS_REQUIRED: { name: 'demographics.address.required', defaultValue: 'true'},
	DEMOGRAPHICS_SPOKEN_LANGUAGE_REQUIRED: { name: 'demographics.language.required', defaultValue: 'false'},
	DEMOGRAPHICS_WRITTEN_LANGUAGE_REQUIRED: { name: 'demographics.written.language.required', defaultValue: 'false'},
	DEMOGRAPHICS_EMAIL_REQUIRED: { name: 'demographics.email.required', defaultValue: 'false'},
	DEMOGRAPHICS_RACE_REQUIRED: { name: 'demographics.race.required', defaultValue: 'false'},
	DEMOGRAPHICS_ETHNICITY_REQUIRED: { name: 'demographics.ethnicity.required', defaultValue: 'false'},
	DEMOGRAPHICS_REFERRED_BY_REQUIRED: { name: 'demographics.referredBy.required', defaultValue: 'false'},
	DEMOGRAPHICS_INTERPRETER_ASSISTANCE_REQUIRED: { name: 'demographics.interpreter.assistance.required', defaultValue: 'false'},
	CCDA_TRANSITION_OF_CARE_DEFAULT_SECTIONS: { name: 'ccda.transitionofcare.default.sections'},
	CCDA_TRANSITION_OF_CARE_DEFAULT_FOLDER: { name: 'ccda.transitionofcare.default.folder'},
	CCDA_CLINICAL_SUMMARY_DEFAULT_SECTIONS: { name: 'ccda.clinicalsummary.default.sections'},
	CCDA_CLINICAL_SUMMARY_DEFAULT_FOLDER: { name: 'ccda.clinicalsummary.default.folder'},
	CCDA_PATIENT_RECORD_DEFAULT_FOLDER: { name: 'ccda.patientrecord.default.folder'},

	PATIENTS_DEFAULT_SUMMARY_VIEW: { name: 'patients.default.summary.view', defaultValue: 'patients.default.summary.view.patientsummary',
		values: {
			PATIENTS_DEFAULT_SUMMARY_VIEW_PATIENTSUMMARY     : 'patients.default.summary.view.patientsummary',
			PATIENTS_DEFAULT_SUMMARY_VIEW_OPTICALSUMMARY   : 'patients.default.summary.view.opticalsummary',
		},
	},
	OPTICAL_SUMMARY_DEFAULT_VIEW: { name: 'optical.summary.default.view', defaultValue: 'optical.summary.default.view.eyeglass.details',
		values: {
			OPTICAL_SUMMARY_DEFAULT_VIEW_EYEGLASS_DETAILS     : 'optical.summary.default.view.eyeglass.details',
			OPTICAL_SUMMARY_DEFAULT_VIEW_CONTACTLENS_DETAILS   : 'optical.summary.default.view.contactlens.details',
		},
	},
	RECALL_LOCATION_MAPPING_ENABLED: { name: 'recall.location.mapping.enabled', defaultValue: 'false' },
	DEMOGRAPHICS_EMPLOYMENT_SCHOOL_STATUS_REQUIRED: { name: 'demographics.employment.school.status.required', defaultValue: 'false' },
	DEMOGRAPHICS_EMPLOYER_REQUIRED: { name: 'demographics.employer.required', defaultValue: 'false' },
	ONGOING_CARE_DEFAULT_TAB: { name: 'ongoingcare.default.tab', defaultValue: 'refractions',
		values: { DEFAULT: 'refractions', REFRACTIONS: 'refractions', GLAUCOMA: 'glaucoma'},
	},
};

